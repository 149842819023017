import { serviceDetailKeys } from "./configConstants";
import bankGuarantee from "../image/bank-guarantee.svg";
import letterOfCredit from "../image/letter-of-credit.svg";
import machineryLoan from "../image/machinery-loan.svg";
import startupLoan from "../image/startup-loan.jpg";
import businessLoan from "../image/business-loan.svg";
import dropLineOverDraftLimit from "../image/dropline-overdraft-limit.svg";
import overDraftLimit from "../image/over-draft-limit.svg";
import termLoan from "../image/term-loan.jpg";
import cashCreditLimit from "../image/cash-credit-limit.svg";
import schoolFunding from "../image/school-funding.svg";
import realEstateFunding from "../image/real-estate-funding.jpg";
import constructionLoan from "../image/construction-loan.svg";
import projectFinanceLoan from "../image/project-finance-loan.svg";

export const nonFundBasedServiceList = [
  {
    [serviceDetailKeys.imageUrl]: bankGuarantee,
    [serviceDetailKeys.title]: "Bank Guarantee",
    [serviceDetailKeys.infoList]:[
      "Reduced Risk and Cost Associated with Business Operations", 
      "Competitive Commission Rate", 
      "Get 150% Funding against Value of Collateral", 
      "Collateral can be Mix of Property and Fixed Deposits",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: letterOfCredit,
    [serviceDetailKeys.title]: "Letter of Credit",
    [serviceDetailKeys.infoList]:[
      "Reduced Credit Risk", 
      "Flexible Payment Terms", 
      "Facilitates Complex Transactions", 
      "Local and Global Acceptance",
    ]
  }
]

export const projectFinanceServiceList = [
  {
    [serviceDetailKeys.imageUrl]: projectFinanceLoan,
    [serviceDetailKeys.title]: "Project Finance",
    [serviceDetailKeys.infoList]:[
      "Long Term Loan", 
      "Moratorium Available (till Project Completion and Start of Cash Flows)", 
      "Zero or Limited Resources Financing Solution", 
      "Current Cash Flows not Required (unlike equity based investors)",
      "Loan based on Feasibility of Project",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: constructionLoan,
    [serviceDetailKeys.title]: "Construction Loans",
    [serviceDetailKeys.infoList]:[
      "Low Rate of Interest", 
      "Flexible and Long Tenure", 
      "Loan Amount Disbursed as per Construction Phase Requirement", 
      "Interest to be paid on Disbursed Amount",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: realEstateFunding,
    [serviceDetailKeys.title]: "Real Estate",
    [serviceDetailKeys.infoList]:[
      "Low Rate of Interest (as per Risk Involved and Project Feasibility)", 
      "Customizable Disbursement Services (Including NOC & Escrow Management)", 
      "Flexible Loan Repayment Terms", 
      "Moratorium Available (as per Requirement and Eligibility)",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: schoolFunding,
    [serviceDetailKeys.title]: "School/Hospital Funding",
    [serviceDetailKeys.infoList]:[
      "Wide range of acceptable Collateral", 
      "Available for Construction, Renovation, Expansion or Purchase", 
      "Equipment Loan", 
      "Competitive rate of interest",
    ]
  }
]

export const workingCapitalLoanServiceList = [
  {
    [serviceDetailKeys.imageUrl]: cashCreditLimit,
    [serviceDetailKeys.title]: "CC Limit",
    [serviceDetailKeys.infoList]:[
      "Insured by Govt. of India", 
      "Unsecured CC limit upto 5 Crores (under CGTMSE scheme)", 
      "Upto 20% of the Firm’s Turnover", 
      "Debtors and Stock taken as Collateral (instead of Property/ FD)",
      "Competitive Interest Rates",
      "Interest is charged only on Utilized Amount",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: termLoan,
    [serviceDetailKeys.title]: "Term Loan",
    [serviceDetailKeys.infoList]:[
      "Low and Competitive Rate of Interest",
      "Overtime Decline of Service Burden of Loan",
      "Nil Forclosure Charges for Floating Rate of Interest",
      "Customizable as per Client’s Requirements",
      "High Loans against Property (SORP, SOCP or SOIP)",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: overDraftLimit,
    [serviceDetailKeys.title]: "Over Draft Limit",
    [serviceDetailKeys.infoList]:[
      "No EMI based Repayment",
      "Interest charged only on Utilized Amount",
      "Secured and Unsecured Options (as per Requirements and Eligibility)",
      "Minimum Monthly Payment Option",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: dropLineOverDraftLimit,
    [serviceDetailKeys.title]: "Dropline Overdraft",
    [serviceDetailKeys.infoList]:[
      "High Tenure",
      "No Renewal Charges",
      "Secured and Unsecured Options (as per Eligibility)",
      "Monthly Decline in Sanctioned Limit (not turnover based)",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: businessLoan,
    [serviceDetailKeys.title]: "Business Loan",
    [serviceDetailKeys.infoList]:[
      "Get loan in just two days",
      "Without any collateral",
      "Pay back easily in monthly EMIs",
      "Fixed Tenure of 3 years",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: machineryLoan,
    [serviceDetailKeys.title]: "Machinery Loan",
    [serviceDetailKeys.infoList]:[
      "Competitive Interest Rate",
      "Flexible Tenure",
      "Loan amount from 10 lakhs upto 20 Crore",
      "Unsecured Loan ",
    ]
  },
  {
    [serviceDetailKeys.imageUrl]: startupLoan,
    [serviceDetailKeys.title]: "Start-up Loan",
    [serviceDetailKeys.infoList]:[
      "Low Rate of Interest (Subsidiary from Govt of India)",
      "Simpler and Faster Process (than Venture Capitals)",
      "No Profit Sharing (unlike Venture Capitals)",
      "Both Secured and Unsecured Options",
      "Limit Facility",
    ]
  }
]