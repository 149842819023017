import { isEmpty } from 'lodash';
import React from 'react';
import { serviceDetailKeys } from '../constants/configConstants';


class ServiceDescriptionPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    if(isEmpty(this.props.serviceList)) {
      return null;
    }

    return (
      <section id="service-desc-page">
        <div className='service-desc-page-container'>
          <div className="service-header">{this.props.header}</div>
          <div className="container service-content-container">
            <div className="row service-content">
              {
                [...this.props.serviceList].map((serviceObj, idx) => (
                  <div className="col-md-3 service-card" key={idx}>
                    <img src={serviceObj[serviceDetailKeys.imageUrl]} alt={serviceObj[serviceDetailKeys.title]}/>
                    <div className="card-body">
                      <h3>{serviceObj[serviceDetailKeys.title]}</h3>
                      <div className="card-text">
                        {
                          (serviceObj[serviceDetailKeys.infoList] || []).map(infoText => (
                            <p>{infoText}</p>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default ServiceDescriptionPage;
