import Carousel from 'react-bootstrap/Carousel';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { chunk } from 'lodash';


const CLIENT_IMG_URL_PREFIX = "https://www.mudrahome.com/public/frontend/images/clients/";

const carousalSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  pauseOnFocus: true,
};

const bankingPartners = [
  require("../image/bankingPartners/adityaBirla.png"),
  require("../image/bankingPartners/anzBank.png"),
  require("../image/bankingPartners/axisBank.png"),
  require("../image/bankingPartners/edelweiss.png"),
  require("../image/bankingPartners/bajajFinance.png"),
  require("../image/bankingPartners/bankOfBaroda.png"),
  require("../image/bankingPartners/capitalFirst.png"),
  require("../image/bankingPartners/chola.png"),
  require("../image/bankingPartners/citiBank.png"),
  require("../image/bankingPartners/csbBank.jpg"),
  require("../image/bankingPartners/ctbcBank.jpg"),
  require("../image/bankingPartners/dbsBank.png"),
  require("../image/bankingPartners/dcbBank.png"),
  require("../image/bankingPartners/deutscheBank.png"),
  require("../image/bankingPartners/dohaBank.png"),
  require("../image/bankingPartners/federalBank.jpg"),
  require("../image/bankingPartners/fullertonIndia.png"),
  require("../image/bankingPartners/standardChartered.png"),
  require("../image/bankingPartners/hdfc.png"),
  require("../image/bankingPartners/icici.png"),
  require("../image/bankingPartners/idbiBank.jpg"),
  require("../image/bankingPartners/idfcbank.png"),
  require("../image/bankingPartners/indusInd.png"),
  require("../image/bankingPartners/kotak.png"),
  require("../image/bankingPartners/karnatakaBank.png"),
  require("../image/bankingPartners/karurVysya.png"),
  require("../image/bankingPartners/kookminBank.jpg"),
  require("../image/bankingPartners/magma.png"),
  require("../image/bankingPartners/piramal.png"),
  require("../image/bankingPartners/pnbHousing.png"),
  require("../image/bankingPartners/poonawallaFincorp.png"),
  require("../image/bankingPartners/rblBank.png"),
  require("../image/bankingPartners/shinhanBank.png"),
  require("../image/bankingPartners/shriRamFinance.png"),
  require("../image/bankingPartners/smbcCorporation.jpg"),
  require("../image/bankingPartners/auBank.png"),
  require("../image/bankingPartners/tataCapital.png"),
  require("../image/bankingPartners/utkarshBank.jpg"),
  require("../image/bankingPartners/wooriBank.jpg"),
  require("../image/bankingPartners/yesBank.png"),
]

function Clients() {

  const chunkSize = (window.innerWidth <= 760) ? 10 : 20;

  return  (
    <section id="clients" class="wow fadeInUp">
      <div class="container">
        <header class="section-header">
        <h3>Banking Partners</h3>
        </header>
      </div>
      <div className="container">
        <Slider {...carousalSettings}>
          {
            chunk([...bankingPartners], chunkSize).map((chunkArr, idx) => (
              <div className="clients-wrapper" key={idx}>
                {
                  chunkArr.map((img, index) => (
                    <img key={index} src={img} />
                  ))
                }
              </div>
            ))
          }
        </Slider>
      </div>
    </section>
  )
}

export default Clients;