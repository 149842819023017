import React from 'react';
import Contact from './components/Contact';
import About from './components/About';
import CoreValues from './components/CoreValues';
import Facts from './components/Facts';
import Home1 from './components/Home1';
import Footer from './components/Footer';
import Clients from './components/Client';
import Expertise from './components/Expertise';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    this.scrollToSection();
    window.addEventListener("hashchange", this.scrollToSection)
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.scrollToSection)
  }

  scrollToSection = () => {
    const hash = window.location.hash.replace('#', '');
    const element = document.getElementById(hash);
    if(!element) {
      return;
    }

    window.scrollTo({
      top: element.offsetTop - 50,
      behavior: 'smooth',
    });
  }

  render() {

    return (
      <div>
        <Home1 />
        <About />
        <Expertise />
        <CoreValues />
        <Clients />
        <Facts />
        <Contact />
        <Footer />
      </div>
    )
  }
}

export default HomePage;
