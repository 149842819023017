import React from 'react';
import logo from "../image/AfasLogo.jpg";

function renderStatsOriginal() {
  return (
    <div className="stats-section-container">
      <div className="custom-column" style={{width: '45%'}}>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#EE9241", height: "10rem", width: "80%"}}> 125+ NBFCs</div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#C3C7C4", height: "12rem"}}>125+ NBFCs</div>
        </div>
      </div>
      <div className="custom-column" style={{width: '45%'}}>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#438FF6", height: "5rem"}}>125+ NBFCs</div>
          <div className="stat-child" style={{background: "#FFE530", height: "5rem"}}>125+ NBFCs</div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#71C960", height: "15rem"}}> 125+ NBFCs</div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#D0B5F9", height: "8.5rem"}}>125+ NBFCs</div>
          <div className="stat-child" style={{background: "#B3F0F0", height: "5rem"}}>125+ NBFCs</div>
        </div>
      </div>
    </div>
  )
}

function renderStats() {

  return (
    <div className="stats-section-container">
      <div className="custom-column" style={{width: '45%'}}>
        <div className="custom-row">
          <div className="stat-child" style={{backgroundImage: "linear-gradient(to right, #C3C7C4, #0F316E)", height: "10rem", width: "80%"}}> 
            <div style={{fontSize:50}}>55+</div>
            <div>Banks & NBFCs</div>
          </div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{backgroundImage: "linear-gradient(to right, #C3C7C4, #0F316E)", height: "10rem"}}>
            <div style={{fontSize:40}}>1500Cr+</div>
            <div>Disbursed</div>
          </div>
        </div>
      </div>
      <div className="custom-column" style={{width: '45%'}}>
        <div className="custom-row">
          <div className="stat-child" style={{backgroundImage: "linear-gradient(#C3C7C4, #0F316E)", height: "7rem"}}>
            <div style={{fontSize:35}}>50+</div>
            <div>Consultants</div>
          </div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{background: "#0F316E", height: "10rem", color: "#FFF"}}> 
            <div style={{fontSize:50}}>500+</div> 
            <div>Happy Customers</div>
          </div>
        </div>
        <div className="custom-row">
          <div className="stat-child" style={{backgroundImage: "linear-gradient(#0F316E, #C3C7C4)", padding: "1rem"}}>
            <div style={{fontSize:50}}>10+</div>
            <div>Cities & growing...</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Home1() {
  return (
    <div className="animated-number-section">
      <div className="image">
        <img alt="placeholder" src={logo} />
      </div>
      <div className="image">
        { renderStats() } 
      </div>
    </div>
  );
}

export default Home1;
