import React, { useState } from 'react';
import calculator from "../image/calculator.svg";

const Facts = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loanDuration, setLoanDuration] = useState(0);
  const [emi, setEmi] = useState(0);

  const calculateEmi = () => {
    if(!loanAmount || !interestRate || !loanDuration) {
      setEmi(0);
      return;
    }

    const P = loanAmount;
    const R = interestRate / 1200; // convert interest rate to monthly rate
    const N = loanDuration * 12; // convert loan duration to months
    const EMI = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
    setEmi(EMI);
  };

  const handleLoanAmountChange = (event) => {
    setLoanAmount(event.target.value);
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);
  };

  const handleLoanDurationChange = (event) => {
    setLoanDuration(event.target.value);
  };


  return (
    <React.Fragment>
      <>
      <section id="emi">
      <div class="emi">
        
      <div class="container py-7">
        <div class="row emi-container">
          <div className='col-md-5'>
            <img src={calculator} alt='emi-calculator'/>
          </div>
          <div className='col-md-5 emi-text-container'>
            <h2 style={{fontWeight: 'bold'}}>Plan your finances with our calculator</h2>
            <button id="mybutton myModal1" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Calculate EMI
            </button>
          </div>

            <div class="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
           
                      <p class="heading">EMI Calculator</p>
                      <div class="input-container">
                        <label htmlFor="amount">Loan Amount(₹)</label>
                        <input type="number" pattern="[0-9.]*" id="loan-amount" value={loanAmount} onChange={handleLoanAmountChange} />
                      </div>
                      <div class="input-container">
                        <label htmlFor="interest">Interest Rate(%)</label>
                        <input type="number" pattern="[0-9.]*" id="interest-rate" value={interestRate} onChange={handleInterestRateChange} />
                      </div>
                      <div class="input-container loan-tenure-container">
                        <label htmlFor="loanTenure">Loan Tenure</label>
                        <input type="number" pattern="[0-9.]*" id="loan-duration" value={loanDuration} onChange={handleLoanDurationChange} />
                      </div>
                  
                  <div class="submit-container">
                    <button onClick={calculateEmi}>Calculate EMI</button>
                  </div>

                  <div class="output">
                    <p >EMI: {emi.toFixed(2)}</p>

                  </div>
   
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn bg-gradient-dark" data-bs-dismiss="modal">Close</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div >
      </section>
      </>

    </React.Fragment >
  )
}

export default Facts;

