import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class MapLocator extends React.Component {
  constructor(props){
    super(props);
  }

  render() {

    return (
        <MapContainer center={this.props.position} zoom={15} style={{ width: "100%", height: "100%" }}>
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={this.props.position}>
            <Popup>
              Ananaya Financial & Advisory Services
            </Popup>
          </Marker>
        </MapContainer>
    )
  }
}

export default MapLocator;
