import React from 'react'

const CoreValues = () => {
  return (
    <React.Fragment>
    
    <section id="facts">
    <div class="container" >

      <header class="section-header">
        <h3>Core Values</h3>
        <p>Our core values guide us in our journey to become the reliable advisor and brand that distinguishes us from all.</p>
      </header>

      <div class="row">

        <div class="col-lg-4 col-md-6 box" >
          <div class="exicon1"><img src="assets/img/customerfocus.png"></img></div>
          <h4 class="title">Customer-focused</h4>
           </div>
        <div class="col-lg-4 col-md-6 box">
        <div class="exicon1"><img src="assets/img/acc.png"></img></div>
          <h4 class="title">Accountability</h4>
          
        </div>
        <div class="col-lg-4 col-md-6 box" >
        <div class="exicon1"><img src="assets/img/confident.png"></img></div>
          <h4 class="title">Confidentiality</h4>
             </div>
            
        <div class="col-lg-4 col-md-6 box" >
        <div class="exicon1"><img src="assets/img/trans.png"></img></div>
          <h4 class="title">Transparency</h4>

        </div>
        <div class="col-lg-4 col-md-6 box">
        <div class="exicon1"><img src="assets/img/intergrityy.png"></img></div>
          <h4 class="title">Integrity</h4>
          
        </div>
        <div class="col-lg-4 col-md-6 box">
        <div class="exicon1"><img src="assets/img/loyality.png"></img></div>
        <h4 class="title">Loyalty</h4>
        
      </div>

      </div>
    </div>


</section>
    </React.Fragment>
  )
}

export default CoreValues
