import React from 'react';
import MapLocator from './MapLocator';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const renderMapLocator = () => {

  return (
    <div className='footer-map-locator-container'>
      <MapLocator
        position={[28.703438, 77.193994]}
      />
    </div>
  )
}

const Footer = () => {

  return (
    <footer id="footer" className="footer">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
            <a href="/" className="logo d-flex align-items-center">
              <span>Ananya Financial And Advisory Services</span>
            </a>
            <p className="text-justify">Ananaya Financial &amp; Advisory Services Private Limited sees potential in your vision and strives to meet all your financial needs. We are committed to provide you a hassle-free banking experience. We offer a premium range of services curated as per your requirements.</p>
            <div className="social-links d-flex mt-4">
              <a href="https://twitter.com/" target="_blank"><i className="bi bi-twitter"></i></a>
              <a href="https://www.facebook.com/" target="_blank"><i className="bi bi-facebook"></i></a>
              <a href="https://www.instagram.com/" target="_blank"><i className="bi bi-instagram"></i></a>
              <a href="https://www.linkedin.com/" target="_blank"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
          <div className="col-lg-2 col-6 footer-links ">
            <h4>Useful Links</h4>
            <ul className="bg-white">
              <li><a href="#">Home</a></li>
              <li><a href="#emi">EMI Calculator</a></li>
              <li><a href="#about">About us</a></li>
              <li><a href="#services">Services</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul className="bg-white">
              <li><Link to="capital-loans">Working Capital Loans</Link></li>
              <li><Link to="project-finance">Project Finance</Link></li>
              <li><Link to="non-fund-based">Non Fund Based</Link></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            {renderMapLocator()}
            <p><strong>Visit:</strong> <span></span>B-6/9,1st Floor, Model Town, Delhi-110009</p>
            <p><strong>Phone:</strong> <span>9560047549</span></p>
            <p><strong>Email:</strong> <span>connect@ananyafas.com</span></p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
