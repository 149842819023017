import { isEmpty } from "lodash";
import React from "react";
import { LoanIcon, NonFundBasedIcon, ProjectFinanceIcon } from "./Icons";
import { Link } from "react-router-dom";

const servicesOffered = [
  {
    icon: <LoanIcon />,
    title: "Working Capital Loans",
    description: "Bridging your business needs for unpredictable cash flows to sustain operations and drive growth",
    link: "capital-loans"
  }, 
  {
    icon: <ProjectFinanceIcon />,
    title: "Project Finance",
    description: "Involves funding long-term projects that caters the cashflows generated and enabling repayment of equity and debt",
    link: "project-finance"
  },
  {
    icon: <NonFundBasedIcon />,
    title: "Non Fund Based",
    description: "Non-fund based limits in banking are commitments, not cash, acting as a safety net for banks, turning into obligations if customers don't fulfill their commitments",
    link: "non-fund-based"
  }
]

class Services extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="services" className="services-container">
        <div className="header">Our Services</div>
        <div className="container">
          <div className="row body">
            {
              servicesOffered.map(serviceObj => {
                if(isEmpty(serviceObj)) {
                  return null;
                }

                return (
                  <div className="col-md-3 service-item-card-container">
                    <span className="icon">{serviceObj.icon}</span>
                    <h4 style={{fontSize:"1.25rem", fontWeight:"700"}}>{serviceObj.title}</h4>
                    <p>{serviceObj.description}</p>
                    <Link to={serviceObj.link}>MORE</Link>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
}

export default Services;
