import React from 'react';
import ServiceDescriptionPage from "./components/ServiceDescriptionPage";
import Services from "./components/Services";
import { Switch, Route } from "react-router-dom";
import AppHeader from './components/AppHeader';
import HomePage from './HomePage';
import './App.css';
import { nonFundBasedServiceList, projectFinanceServiceList, workingCapitalLoanServiceList } from './constants/serviceConstants';

function App() {

  return (
    <div className="App">
      <AppHeader />
      <Switch>
        <Route path="/services"><Services /></Route>
        <Route path="/capital-loans">
          <ServiceDescriptionPage 
            header="Working Capital Loans"
            serviceList={workingCapitalLoanServiceList}
          />
        </Route>
        <Route path="/project-finance">
          <ServiceDescriptionPage 
            header="Project Finance Service"
            serviceList={projectFinanceServiceList}
          />
        </Route>
        <Route path="/non-fund-based">
          <ServiceDescriptionPage 
            header="Non Fund Based Service"
            serviceList={nonFundBasedServiceList}
          />
        </Route>
        <Route path="*"><HomePage /></Route>
      </Switch>
    </div>
  );
}

export default App;
