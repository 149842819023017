import { isEmpty } from "lodash";
import React from "react";

const USPs = [
  {
    iconClass: 'bi bi-clipboard2-check-fill',
    text: 'Unique and Ideal Solutions that suit all your needs'
  },
  {
    iconClass: 'bi bi-bank2',
    text: 'Get the Bank that provides you with the Best Loan Offers'
  },
  {
    iconClass: 'bi bi-mortarboard-fill',
    text: 'Ease Your Journey: Bid Farewell to Loan Formalities, Complications and Queries'
  },
  {
    iconClass: 'bi bi-people-fill',
    text: 'Our Experts guide you through every step of your financial growth'
  }
]

class Expertise extends React.Component {
  constructor(props){
    super(props);
  }

  render() {

    return (
      <section id="whyafas">
        <div class="section-header">
          <h3>“You dream, we make it your reality!”</h3>

        </div>

        <div className="usp-container">
          {
            Object.values(USPs).map(obj => {
              if(isEmpty(obj)) {
                return null;
              }

              return (
                <div key={obj.text}>
                  <i className={`usp-icon ${obj.iconClass}`}></i>
                  <h4 className="fs20 fw-600 ">{obj.text}</h4>
                </div>
              )
            })
          }
        </div>
      </section>
    );
  }

}

export default Expertise;
